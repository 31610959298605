<template>
  <div id="faq">
    <v-flex xs12>
      <v-layout wrap class="faq">
        <v-flex xs11 sm11 md11 offset-sm1 offset-md1 v-if="!isMobile">
          <span class="text"> FAQ </span>
        </v-flex>
        <v-flex xs10 sm10 md10 offset-xs1 offset-sm1 offset-md1 class="mt-9">
          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="(item,i) in data"
              :key="i"
            >
              <v-expansion-panel-header>
                <span class="text-1"> {{ item.pertanyaan }} </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <span class="text-2 pt-2" v-html="item.jawaban"></span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
         </v-flex>
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'Faq',
    computed: {
      ...mapState([
        'isMobile'
      ])
    },
    data () {
      return {
        data: null
      }
    },
    methods: {
      getFAQ () {
        this.$store.commit('setIsLoading', true)
        axios.get('/api/v1/pages/faq')
          .then(response => {
            this.data = response.data.data
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      }
    },
    mounted () {
      this.getFAQ()
      this.setMetaInfo(null, this.$route.name)

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setActivePage', 'FAQ')
    }
  }
</script>

<style scoped>
  .text {
    font-size: 1.7rem;
    color: #000000;
    font-weight: 600;
  }

  .text-1 {
    font-size: 12px;;
    color: #000000;
    font-weight: bold;
  }

  .text-2 {
    margin-top: 1rem;
    font-size: 12px;;
    color: #000000;
    text-align: justify;
    text-justify: inter-word;
  }

  .faq {
    color: #FFFFFF;
    position: relative;
    margin: auto;
    margin-top: 10%;
  }
</style>
